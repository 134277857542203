import React from "react";
import removeAccents from "remove-accents";
import "./App.css";

function calcultateScore(name) {
  return removeAccents(name)
    .toLowerCase()
    .replace(/[^a-z]/g, "")
    .split("")
    .map((c) => c.charCodeAt() - 96)
    .reduce((a, b) => a + b, 0);
}

/**
 * a: start value
 * b: end value
 * t: current time
 * d: total duration
 */
function easeOut(a, b, t, d) {
  t /= d;
  return a + (b - a) * (1 - Math.pow(1 - t, 5));
}

function Score({ value: targetValue }) {
  const [value, setValue] = React.useState(targetValue);
  const valueRef = React.useRef(targetValue);
  React.useEffect(() => {
    if (valueRef.current === targetValue) return;

    const initialValue = valueRef.current;
    const duration = 800;
    const startTime = Date.now();
    let requestId;

    function schedule() {
      requestId = window.requestAnimationFrame(() => {
        const ellapsed = Math.min(Date.now() - startTime, duration);
        const nextValue = easeOut(
          initialValue,
          targetValue,
          ellapsed,
          duration
        );
        valueRef.current = Math.round(nextValue);
        setValue(valueRef.current);
        if (ellapsed < duration) schedule();
      });
    }

    schedule();

    return () => {
      if (requestId) window.cancelAnimationFrame(requestId);
    };
  }, [targetValue]);

  return value;
}

export default function App() {
  const [name, setName] = React.useState("");
  const score = calcultateScore(name);
  return (
    <div className="App">
      <h1>Combien vaut ton prénom ?</h1>
      <input
        type="text"
        placeholder="Ton prénom"
        onInput={(e) => setName(e.target.value)}
        className="App-input"
      />
      <div className="App-score">
        <Score value={score} />
      </div>

      <p>La valeur de ton prénom ne présage rien de ta valeur personnelle ♥️</p>
    </div>
  );
}
